import "./style.css";
import { motion } from "framer-motion"
import { useEffect, useState } from "react";
import { Imagen } from "../../interfaces/imagen";
import { FaChevronCircleRight } from "react-icons/fa";

interface ServicioProps{
    info?: Imagen;
}

export default function CardListServicioMarketing({info}: ServicioProps){
    const [servicio, setServicio] = useState(info);

    useEffect(() => {
        setServicio(info);
    },[]);

    return (
        <motion.li key={servicio?.id} className="marketing__item d-flex col-lg-12"
        initial={{ opacity: 0, x:-100 }}
        whileInView={{ opacity: 1, x:0 }}>
            <div className="marketing__li d-flex gap-3">
                {/* <FaChevronCircleRight className="marketing__icon pt-1" /> */}
                <p className="marketing__list__number"><u>0{servicio?.id}</u></p>
                <div className="d-flex flex-column justify-content-start">
                    <h5 className="fw-bold mb-2">{servicio?.titulo}</h5>
                    <p>{servicio?.descripcion}</p>
                </div>            
            </div>
        </motion.li>
    );
}