import './style.css'
import Footer from "../../components/footer";
import Header from "../../components/header";
import TopBanner from "../../components/top-banner";
import Contacto from '../../components/contacto';
import WhatsappButton from '../../components/whatsapp-button';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import ReactGA from 'react-ga';
import HeaderSpace from '../../components/header-space';
import SectionTextImage from '../../components/sectionTextImage';
import { Imagen } from '../../interfaces/imagen';
import imgNosotros from '../../media/img/home/image002.jpg';
import imgMarketing from '../../media/img/home/marketing_2.png';
import HomeServicios from './servicios';
import HomeMarcas from './marcas';

export default function Home() {
  const { search } = useLocation();  
  const id = new URLSearchParams(search).get("id");

  const seccionNosotros: Imagen = {
    id: 1,
    titulo: `Sobre nosotros`,
    subtitulo: '',
    descripcion: `Somos Estudio Hargouas, un estudio contable fundado en 1982 por Horacio Martín Hargouas. 
Nuestra experiencia abarca todos los ámbitos de la contabilidad empresarial, lo que nos permite ofrecer soluciones integrales y personalizadas para cada cliente.
    
Nuestro equipo de profesionales altamente capacitados se dedica a proporcionar un servicio confiable y eficiente, siempre guiado por los valores de integridad, compromiso y excelencia. En Estudio Hargouas, nos esforzamos por entender las necesidades únicas de cada cliente y proporcionar asesoramiento contable que impulsa su crecimiento y éxito

Contador Público: Horacio Martín Hargouas - Legajo 13709/0
`,
    imagenUrl: imgNosotros,
    tipoSeccion: {
        id: 3,
        descripcion: 'Seccion'
    }
  }  

  const seccionMarketing: Imagen = {
    id: 1,
    titulo: `Marketing digital`,
    subtitulo: '',
    descripcion: `Además, debido a la alta demanda de nuestros clientes, desde hace más de 5 años brindamos asesoramiento en marketing digital, ayudando a las empresas a mejorar su presencia online y a alcanzar sus objetivos comerciales. 
    
Somos un estudio que nos destacamos por brindar más servicios de los tradicionales para satisfacer las necesidades que nuestros clientes nos solicitan.`,
    imagenUrl: imgMarketing,
    tipoSeccion: {
        id: 3,
        descripcion: 'Seccion'
    }
  }

  useEffect(() => {
    //ReactGA.initialize('G-MVTJ9F4F2L'); 
    document.title = 'Hargouas';
    ReactGA.pageview(window.location.pathname);
    if(id == undefined || id==""){
      window.scrollTo(0, 0);
    }
    setTimeout(() => {
      document.querySelector(`#${id}`)?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',          
        });
    }, 0);
  },[id])
  

  return (
    <div className="div__home min-vh-100 h-10">
      <Header></Header>
      <TopBanner></TopBanner>

      <HomeServicios></HomeServicios>

      <section id="nosotros" className="container-fluid pt-5 h-100">
        {/* <h3 className="text-center mb-4 fw-bold">Sobre nosotros</h3> */}
        <SectionTextImage info={seccionNosotros}></SectionTextImage>
      </section>

      <section id="marketing" className="marketing__container container-fluid mt-5 pt-5 h-100">
        <SectionTextImage info={seccionMarketing} inverse={true} buttonText="Más Información" buttonLink="marketing"></SectionTextImage>
      </section>

      <HomeMarcas></HomeMarcas>

      <section id="contacto" className="h-100 pt-5">
        <Contacto data={''} destinatario="estudio@hargouas.com.ar"></Contacto>
      </section>

      <Footer></Footer>
      <WhatsappButton></WhatsappButton>
    </div>
  )
}
