import "./style.css";
import { useEffect, useState } from "react";
import { Partner } from "../../interfaces/partner";

interface CardPartnerProps{
    data?: Partner;
}

export default function CardPartner({data}: CardPartnerProps){
    const [partner, setPartner] = useState(data);

    useEffect(() => {
        setPartner(data);
    }, []);

    return (
        <div className="item firstImage d-flex justify-content-center align-items-center">
            <div className="logoWrap d-flex justify-content-center align-items-center">
                <img src={data?.imagen} alt="Logo" height={64}/>
            </div>
        </div>
    )
}