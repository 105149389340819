import { Servicio } from "../interfaces/servicio";
import imgImpositivos from "../media/img/icons/impositivos.png";
import imgContables from "../media/img/icons/contables.png";
import imgSueldos from "../media/img/icons/sueldos.png";
import imgSocietarios from "../media/img/icons/societarios.png";
import imgMarketing from "../media/img/icons/marketing.png";

export const MockServicios: Servicio[] = [
    {
        imagen: imgImpositivos,
        titulo: "IMPOSITIVOS",
        items: [
            "Liquidación mensual y anual de impuestos",
            "Asesoramiento a Monotributistas",
            "Regímenes de Información y Retención",
            "Tramitación de inscripciones",
        ],
        link: ""
    },
    {
        imagen: imgContables,
        titulo: "CONTABLES",
        items: [
            "Preparación de Estados Contables",
            "Informes y Certificaciones contables",
            "Auditoría de Estados Contables",
            "Análisis y proyección de Estados Contables"
        ],
        link: ""
    },
    {
        imagen: imgSueldos,
        titulo: "SUELDOS Y JORNALES",
        items: [
            "Liquidación de sueldos y jornales",
            "DDJJ AFIP (F931) y boletas sindicales",
            "Altas y bajas de empleados",
            "Asesoramiento proactivo de los CCT"
        ],
        link: ""
    },
    {
        imagen: imgSocietarios,
        titulo: "SOCIETARIOS",
        items: [
            "Encuadre societario para su proyecto.",
            "Constitución de Sociedades.",
            "Reforma de Sociedades."
        ],
        link: ""
    },
    {
        imagen: imgMarketing,
        titulo: "MARKETING DIGITAL",
        items: [
            "Embudos de venta",
            "Maximización de ROI (Retorno de la inversión Publicitaria)",
            "Publicidad Digital (Meta, Google, Tik Tok, etc)",
            "Email Marketing",
            "Asesoramiento digital"
        ],
        link: ""
    }
]