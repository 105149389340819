import { useEffect, useState } from "react";
import "./style.css"
import { Imagen } from "../../interfaces/imagen";
import { MockServiciosMarketing } from "../../mocks/marketing-servicios";
import CardListServicioMarketing from "../../components/cardListServicioMarketing";

export default function MarketingServiciosDescripcion(){
    const [servicios, setServicios] = useState<Imagen[]>();

    useEffect(() => {
        fetchServicios();
    },[])

    const fetchServicios = async () => {
        let serviciosResponse = await MockServiciosMarketing;
        setServicios(serviciosResponse);
    }

    const renderServicios = () => servicios?.map((v, i) => <CardListServicioMarketing info={v} key={i}></CardListServicioMarketing>)

    return (
        <ol className="row">
            {servicios? renderServicios(): ''}
        </ol>
    )

}