import { Servicio } from "../interfaces/servicio";
import imgEmailMarketing from "../media/img/icons/marketing/email-marketing.png";
import imgMarketingAutomation from "../media/img/icons/marketing/marketing-automation.png";
import imgMarketingSocial from "../media/img/icons/marketing/content-marketing.png";
import imgWebDesign from "../media/img/icons/marketing/web-designing.png";

export const MockServiciosMarketing: Servicio[] = [
    {
        imagen: imgEmailMarketing,
        titulo: "EMAIL MARKETING",
        items: [

        ],
        link: ""
    },
    {
        imagen: imgMarketingAutomation,
        titulo: "MARKETING AUTOMATION",
        items: [

        ],
        link: ""
    },
    {
        imagen: imgMarketingSocial,
        titulo: "ANUNCIOS EN REDES SOCIALES",
        items: [

        ],
        link: ""
    },
    {
        imagen: imgWebDesign,
        titulo: "DISEÑO WEB",
        items: [

        ],
        link: ""
    },
]