import { useEffect, useState } from "react";
import { motion } from "framer-motion"
import { Servicio } from "../../interfaces/servicio";
import "./style.css";

interface ServicioProps{
    info?: Servicio;
    openModal?: any;
}

export default function CardServicioMarketing({info, openModal}: ServicioProps){
    const [servicio, setServicio] = useState(info);
    const [servicios, setServicios] = useState<string[]>();

    useEffect(() => {
        setServicio(info);
        setServicios(info?.items);
    },[]);

    const callback = () => {
        openModal(info?.titulo);
    }

    const renderServicios = () => servicios?.map((v, i) => <li key={i}>{v}</li>)

    return (
        <motion.div className="card__servicio__marketing d-flex flex-column justify-content-between align-items-center gap-2 mb-4 py-5" onClick={callback}
        initial={{ opacity: 0, scale:0.5 }}
        whileInView={{ opacity: 1, scale: 1 }}
        >
            <div className="d-flex flex-column justify-content-center align-items-center gap-4 w-100 px-3">
                <img src={servicio?.imagen} alt={servicio?.titulo} width={64}/>
                <h6 className="fw-bold mb-0 text-dark w-100 text-center">{servicio?.titulo}</h6>              
            </div>
        </motion.div>
    );
}