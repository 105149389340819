import { Partner } from "../interfaces/partner";
import cadivi from "../media/img/marcas/cadivi_blanco.png"
import compania from "../media/img/marcas/compania.png"
import cotillon from "../media/img/marcas/cotillon.webp"
import simed from "../media/img/marcas/simed.png"
import solucioneshidrogas from "../media/img/marcas/solucioneshidrogas_azul.png"
import surven from "../media/img/marcas/surven.png"
import unitex from "../media/img/marcas/unitex.jpg"

export const MockPartners: Partner[] = 
[
    {id: 1, descripcion: 'cadivi', imagen: cadivi },
    {id: 2, descripcion: 'compania', imagen: compania },
    {id: 3, descripcion: 'cotillon', imagen: cotillon },
    {id: 4, descripcion: 'simed', imagen: simed },
    {id: 5, descripcion: 'solucioneshidrogas', imagen: solucioneshidrogas },
    {id: 6, descripcion: 'surven', imagen: surven },
    {id: 7, descripcion: 'unitex', imagen: unitex },
]