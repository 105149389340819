import { useEffect, useState } from "react";
import { MockServicios } from "../../mocks/servicios";
import { Servicio } from "../../interfaces/servicio";
import CardServicio from "../../components/cardServicio";
import { Modal } from "react-bootstrap";
import Contacto from "../../components/contacto";

export default function HomeServicios() {
    const [servicios, setServicios] = useState<Servicio[]>();
    const [showModal, setShowModal] = useState<boolean>();
    const [servicioNombre, setServicioNombre] = useState<string>();

    useEffect(() => {
        fetchServicios();
    }, [servicioNombre])

    const fetchServicios = async () => {
        let serviciosResponse = await MockServicios;
        setServicios(serviciosResponse);
    }

    const openModal = (nombre: string) => {
        setServicioNombre(nombre);
        const buttonModal = document.getElementById("buttonModalContacto");
        //buttonModal?.click();
        setShowModal(true);
    }

    const closeModal = () => {
        setShowModal(false);
    }

    const renderServicios = () => servicios?.map((v, i) => <CardServicio info={v} key={i} openModal={openModal}></CardServicio>)

    return (
        <section id="servicios" className="container-fluid pt-5 h-100 servicios__main">
            <h3 className="text-center text-light mb-5 fw-bold">Nuestros Servicios</h3>
            <div className="servicios__container container-fluid d-flex flex-wrap justify-content-evenly gap-4">
                {servicios ? renderServicios() : ''}
            </div>

            <Modal show={showModal} onHide={closeModal}>
                <Modal.Header closeButton className="border-0"></Modal.Header>
                <Modal.Body>
                    {servicioNombre ? <Contacto data={`Hola. Quiero recibir más información sobre ${servicioNombre}`} isModal={true} destinatario="estudio@hargouas.com.ar"></Contacto> : ''}
                </Modal.Body>
            </Modal>
        </section>
    );
};