import { Imagen } from "../interfaces/imagen";

export const MockServiciosMarketing: Imagen[] = [
    {
        id: 1,
        titulo: "Llamada de descubrimiento",
        descripcion: "Tenemos una llamada donde nos contarás todos los detalles de tu negocio. Según sean tus obstáculos haremos un plan a medida que se ajuste a los objetivos comerciales y financieros que tengas."
    },
    {
        id: 2,
        titulo: "Llamada de onboarding",
        descripcion: "En nuestro segundo encuentro presentaremos un plan detallado de acuerdo a los objetivos de la llamada 1. El plan de acción te mostrará los pasos a seguir. Una vez aprobado el plan por el cliente procederemos a la ejecución."
    },
    {
        id: 3,
        titulo: "Ejecución y optimización",
        descripcion: "Utilizaremos los distintos canales del marketing digital como anuncios en redes sociales, email marketing, automatizaciones, etc. Optimizaremos de manera continua las campañas para cada vez tener un mejor margen."
    },    
]