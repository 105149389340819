import { useEffect, useState } from "react";
import { Servicio } from "../../interfaces/servicio";
import "./style.css";
import { useNavigate } from "react-router-dom";

interface ServicioProps{
    info?: Servicio;
    openModal?: any;
}

export default function CardServicio({info, openModal}: ServicioProps){
    const [servicio, setServicio] = useState(info);
    const [servicios, setServicios] = useState<string[]>();
    const [marketing, setMarketing] = useState<boolean>(false);

    useEffect(() => {
        setServicio(info);
        setMarketing(info?.titulo == "MARKETING DIGITAL");
        setServicios(info?.items);
    },[]);

    const callback = () => {
        openModal(info?.titulo);
    }

    const navigate = useNavigate();

    const redirect = () => {
        navigate(`/marketing`);
    }
    const renderServicios = () => servicios?.map((v, i) => <li key={i}>{v}</li>)

    return (
        <div className="card__servicio d-flex flex-column  justify-content-between align-items-center gap-2 mb-4">
            <div className="d-flex flex-column justify-content-center align-items-center gap-2 w-100">
                <img src={servicio?.imagen} alt={servicio?.titulo} width={64}/>
                <h6 className="fw-bold mb-3">{servicio?.titulo}</h6>
                <ul className="servicios__items">
                    {servicios? renderServicios(): ''}
                </ul>
            </div>
            {marketing? <div className="ps-4 align-items-start justify-content-start float-start w-100"><button className="button__primary my-3" onClick={redirect}>
                                Más Información
                            </button></div>: 
                            <a className="mb-2" onClick={callback}>Más Información</a>}
            
        </div>
    );
}