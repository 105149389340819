import './style.css';
import imgLogo from "../../media/img/home/logo_verde.png";
import imgLogo2 from "../../media/img/home/logo_verde.png";
import imgLogoMarketing from "../../media/img/home/logo_marketing.png";
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';

interface HeaderProps {
    opacity?: boolean;
}

export default function Header({ opacity }: HeaderProps) {
    const [styleTop, setStyleTop] = useState(85);
    const [clickedModal, setClickedModal] = useState(false);
    const [bgColor, setBgColor] = useState(opacity ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0.0)');
    const [headerLogo, setHeaderLogo] = useState(imgLogo2);

    const location = useLocation();
    const { hash, pathname, search } = location;
    const [path, setPath] = useState<string>(pathname.replace("/", ""));

    useEffect(() => {
        window.addEventListener('scroll', onScroll);
        setPath(pathname.replace("/", ""));


        setTimeout(() => {
            console.log(pathname.replace("/", ""))
            if (pathname.replace("/", "") == "marketing") {
                setHeaderLogo(imgLogoMarketing);
                document.getElementById("navHeader")?.classList.add("navbar__blur");
            }
            onHeaderItemClick(document.querySelectorAll(".navLink"))
            onImageItemClick(document.querySelectorAll(".nav-item"));
        }, 100);

        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    }, []);

    const onScroll = (e: Event) => {
        
        const window = e.currentTarget as Window
        const header = document.getElementById("navHeader");

        if (window.scrollY > 0) {
            setStyleTop(0);
            let colorBackground = path != "marketing"? "var(--first-color)": "rgba(0,0,0,0.3)";
            setBgColor(colorBackground);
            setHeaderLogo(path != "marketing"? imgLogo: imgLogoMarketing);
        }
        else {
            setStyleTop(85);
            let colorBackground = opacity ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0.0)';
            setBgColor(colorBackground);
            setHeaderLogo(path != "marketing"? imgLogo2: imgLogoMarketing);
        }
        
    }

    const toggleModal = async () => {
        setClickedModal(!clickedModal)
    }

    function onImageItemClick(elements: any) {
        elements.forEach((element: any) => {
            if (element.className.includes("active")) {
                const children = element.children;
                children[0].style.backgroundColor = "var(--dark-color)"
                children[0].style.padding = "19px";
                children[0].style.borderBottom = "6px solid var(--second-color)"
            }
        });
    }

    function onHeaderItemClick(elements: any) {
        elements.forEach((element: any) => {
            element.addEventListener('click', () => {
                document.getElementById('btnToggleNavbar')?.click();
                //ReactGA.pageview(window.location.pathname + "/" + element?.id);
            });
        });
    }

    const returnTop = () => {
        window.scrollTo(0, 0);
    }

    return (
        <header className="header__container">
            <nav className={`navbar navbar-expand-lg`} data-bs-theme="dark" id="navHeader" style={{ backgroundColor: bgColor }}>
                <div className="container-fluid">
                    <a href="#/inicio" className="logo" onClick={returnTop}>
                        <img id="imgLogo" width={200} src={headerLogo} alt="Logo" className="imgLogo" />
                    </a>
                    <button id="btnToggleNavbar" className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse me-5" id="navbarColor01">
                        <ul className="navbar-nav me-auto px-1 navLinkContainer">
                            {/* <li className="nav-item">
                                <a className={`nav-link $"navLink}`} href="#/administracion">ADMINISTRACIÓN</a>
                            </li> */}
                            <li className="nav-item">
                                <a className="nav-link navLink" href={`#/${path}/?id=contacto`}>Contacto</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link navLink" href="#/marketing">Marketing</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link navLink" href="#/inicio/?id=nosotros">Nosotros</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link navLink" href="#/inicio/?id=servicios">Servicios</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link navLink" href="#/inicio" onClick={returnTop}>Inicio</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    )
}