import "./style.css"
import Header from "../../components/header"
import { Imagen } from "../../interfaces/imagen"
import imgMarketing from "../../media/img/marketing/marketing.png"
import SectionTextImage from "../../components/sectionTextImage"
import HeaderSpace from "../../components/header-space"
import Contacto from '../../components/contacto';
import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import ReactGA from 'react-ga';
import Footer from "../../components/footer"
import MarketingServiciosDescripcion from "./servicios-descripcion"
import MarketingServicios from "./servicios"


export default function MarketingPage() {
    const { search } = useLocation();
    const id = new URLSearchParams(search).get("id");

    const seccionMarketing: Imagen = {
        id: 1,
        titulo: `MARKETING DIGITAL`,
        subtitulo: '',
        descripcion: `Hacemos que tu negocio venda más a través de estrategias digitales adaptadas a las necesidades de tu negocio.`,
        imagenUrl: imgMarketing,
        tipoSeccion: {
            id: 3,
            descripcion: 'Seccion'
        }
    }

    useEffect(() => {
        //ReactGA.initialize('G-MVTJ9F4F2L'); 
        document.title = 'Hargouas';
        ReactGA.pageview(window.location.pathname);
        if (id == undefined || id == "") {
            window.scrollTo(0, 0);
        }
        setTimeout(() => {
            document.querySelector(`#${id}`)?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }, 0);
    }, [id])

    return (
        <main className="main__marketing">
            <Header></Header>
            <HeaderSpace></HeaderSpace>

            <section id="banner" className="marketing-banner__container banner__container container-fluid mt-3 h-100">
                <SectionTextImage info={seccionMarketing} inverse={true} buttonText="Contactanos" buttonLink="marketing/?id=contacto"></SectionTextImage>
            </section>

            <section className="container text-light px-5 h-100">
                <h3 className="marketing__title text-center my-5 py-5 fw-bold">¿Cómo lo hacemos?</h3>
                <MarketingServiciosDescripcion></MarketingServiciosDescripcion>
            </section>

            <section className="container text-light px-5 h-100">
                <MarketingServicios></MarketingServicios>
            </section>

            <section id="contacto" className="marketing__contacto mt-3 pt-5 h-100">
                <Contacto data={''} isModal={true} destinatario="marketing@hargouas.com.ar"></Contacto>
            </section>

            <Footer marketing={true}></Footer>
        </main>
    )
}